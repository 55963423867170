<script setup lang="ts">
// const { isGdprRestricted } = storeToRefs(useGdprStore());

const showScroll = ref(true);
const scrollContainer = ref<HTMLElement>();

const { isMobileDevice } = useUtils();

const scrollSections = [
  "homeBanner",
  "timesTravelSection",
  "everSection",
  "eventsSection",
  "postsSection",
];
let currentHash = "homeBanner";
let hashObserver: IntersectionObserver | null = null;

onMounted(() => {
  const { sendScreenView } = useAnalytics();
  sendScreenView({ type: "PageView", target: "index" }, true);

  if (!isMobileDevice()) {
    window.addEventListener("scroll", onScroll);

    hashObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.attributes["data-hash"]) {
              currentHash = entry.target.id;
            }
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    const sections = scrollContainer.value!.querySelectorAll("section");
    sections.forEach((section) => {
      hashObserver!.observe(section);
    });
  }
});

function onScroll() {
  const scrollTop = window.scrollY;
  const element = document.getElementById(scrollSections[scrollSections.length - 1]);
  const y = element!.getBoundingClientRect().top + window.scrollY - 90;
  showScroll.value = scrollTop < y;
}

function onScrollDown() {
  const index = scrollSections.indexOf(currentHash);
  if (index < scrollSections.length - 1) {
    const target = scrollSections[index + 1];
    const element = document.getElementById(target);
    element!.scrollIntoView({ block: "start", behavior: "smooth" });
  }
}

onBeforeUnmount(() => {
  window.removeEventListener("scroll", onScroll);
});
</script>

<template>
  <main ref="scrollContainer" class="container home-container">
    <HomeFeature id="homeBanner" class="snap-section" data-hash="homeBanner" />
    <section id="timesTravelSection" class="snap-section" data-hash="timesTravelSection">
      <TimesTravelFeature />
    </section>
    <section id="everSection" class="snap-section" data-hash="everSection">
      <LazyEverFeature />
    </section>
    <section id="eventsSection" class="snap-section" data-hash="eventsSection">
      <LazyEventsFeature />
    </section>
    <section id="postsSection" class="snap-section" data-hash="postsSection">
      <LazyPostsFeature />
    </section>

    <UiScrollDownButton v-show="showScroll" @click="onScrollDown" />
  </main>
</template>

<style lang="scss">
@use "@/assets/scss/queries";
@use "@/assets/scss/transitions";
@use "@/assets/scss/feature";
@include transitions.fade;
.home-container {
  gap: 0 !important;

  .snap-section {
    scroll-margin-top: var(--header-desktop-collapse-height);

    @include queries.for-size(phone-only) {
      scroll-margin-top: var(--header-mobile-collapse-height);
    }
  }
}
</style>
