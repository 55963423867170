<script setup lang="ts">
const featureContent = ref<HTMLElement>();
const hasTransitioned = ref(false);
let itemObserver: IntersectionObserver | null = null;

onMounted(() => {
  itemObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          hasTransitioned.value = true;
          itemObserver!.unobserve(featureContent.value!);
          itemObserver = null;
        }
      });
    },
    {
      threshold: 0.2,
    }
  );

  itemObserver.observe(featureContent.value!);
});
</script>

<template>
  <div ref="featureContent" class="feature-content">
    <div class="content-container" :class="{ 'transition-start': !hasTransitioned }">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.feature-content {
  overflow: hidden;
  .content-container {
    transition:
      opacity 1.25s ease-out,
      transform 1.25s ease-out;

    @media (max-width: 1024px) {
      transition:
        opacity 1s ease-out,
        transform 0.75s ease-out;
    }

    &.transition-start {
      opacity: 0;
      transform: translateY(-20%);
    }
  }
}
</style>
