<script setup lang="ts">
const videoUrl = ref();

onMounted(() => {
  const { getOrientation } = useUtils();
  if (getOrientation() === "portrait" && window.innerWidth <= 414) {
    videoUrl.value = `${useCdnUrl()}/assets-2024/home/background-video-phone`;
  } else if (getOrientation() === "portrait" && window.innerWidth > 414) {
    videoUrl.value = `${useCdnUrl()}/assets-2024/home/background-video-tablet`;
  } else {
    videoUrl.value = `${useCdnUrl()}/assets-2024/home/background-video-landscape`;
  }
});
</script>

<template>
  <section class="home-feature">
    <UiBackgroundVideo v-if="videoUrl" :src-url="videoUrl" />

    <div class="feature-title">
      <h1>A Modern-day Visitor Center</h1>
      <p>at the intersection of Art, Technology, and Entertainment</p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
.home-feature {
  margin: auto;
  max-width: 2560px;
  min-height: 100vh;

  position: relative;
  width: 100vw;

  .feature-title {
    bottom: 0;
    padding: 8.5rem;
    position: absolute;
    z-index: 1;

    @include queries.for-size(tablet-landscape-down) {
      padding: 6.5rem 3rem;
      text-shadow: 0 0 7px #000;
    }

    @include queries.for-size(phone-only) {
      bottom: 10%;
    }

    h1 {
      color: white;
      font-size: 4.8rem;
      font-weight: 800;
      line-height: 1.17;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }

    p {
      color: white;
      font-size: 2.4rem;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
