<script setup lang="ts">
type BackgroundVideoProps = {
  srcUrl: string;
};
const props = defineProps<BackgroundVideoProps>();

const backgroundVideo = ref<HTMLVideoElement>();
let itemObserver: IntersectionObserver | null = null;

const orientation = ref();

onMounted(() => {
  const { getOrientation } = useUtils();
  orientation.value = getOrientation();

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      backgroundVideo.value!.play();
    } else {
      backgroundVideo.value!.pause();
    }
  });

  itemObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        backgroundVideo.value!.play();
      } else {
        backgroundVideo.value!.pause();
      }
    });
  });

  itemObserver.observe(backgroundVideo.value!);
});
</script>

<template>
  <div class="background-video">
    <video
      ref="backgroundVideo"
      autoplay
      muted
      loop
      playsinline
      :class="{ portrait: orientation === 'portrait' }"
    >
      <source :src="`${props.srcUrl}.mp4`" type="video/mp4" />
      <source :src="`${props.srcUrl}.webm`" type="video/webm" />
    </video>
  </div>
</template>

<style lang="scss" scoped>
.background-video {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  video {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;

    &.portrait {
      // object-fit: contain;
    }
  }
}
</style>
