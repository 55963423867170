<script setup lang="ts">
import type { SignupInfo } from "@/types";

const { sendScreenView } = useAnalytics();

// Means this is opening in modal to send unique PageView
if (window.location.pathname !== "/") {
  sendScreenView({ type: "PageView", target: "stay-in-the-loop-form" }, true);
}

const emit = defineEmits(["close-modal"]);

const signupFormData = ref<SignupInfo>({
  firstname: "",
  lastname: "",
  email: "",
  consent: true,
});

const validationError = ref(false);
const { validTextInput, validEmail } = useFormUtils();
const signupSuccess = ref(false);
const signupError = ref<unknown>(null);
const isSubmitting = ref(false);

async function onSubmitHandler(evt: MouseEvent) {
  evt.preventDefault();

  if (
    !validTextInput.value(signupFormData.value.firstname) ||
    !validTextInput.value(signupFormData.value.lastname) ||
    !validEmail.value(signupFormData.value.email)
  ) {
    validationError.value = true;
    return;
  }

  isSubmitting.value = true;
  const formData = new FormData();
  formData.append("FNAME", signupFormData.value.firstname);
  formData.append("LNAME", signupFormData.value.lastname);
  formData.append("EMAIL", signupFormData.value.email);
  formData.append("CONSENT", 1);
  formData.append("tags", "40191910");

  const config = useRuntimeConfig();

  try {
    const { submitForm } = useMailChimp();
    await submitForm(
      config.public.mailChimpSignupFormId,
      config.public.mailChimpSignupFormFid,
      formData
    );

    onSignupCallback();
  } catch {
    // Ignoring MailChimp form error as we can't catch them
    onSignupCallback();
  }
}

function onSignupCallback(error?: unknown): void {
  if (!error) {
    sendScreenView({
      type: "SignupWeb",
      target: "OTSsignup",
    });
  } else {
    signupError.value = error;
  }
  signupSuccess.value = true;
  isSubmitting.value = false;
}

function onFormReset() {
  signupSuccess.value = false;
  signupFormData.value = {
    firstname: "",
    lastname: "",
    email: "",
    consent: true,
  };

  if (!signupError.value) {
    emit("close-modal");
  } else {
    signupError.value = null;
  }
}
</script>

<template>
  <div class="signup-form">
    <div v-show="!signupSuccess && !isSubmitting" class="sign-up-component">
      <h2>
        Be the first to visit<br />the One Times Square Viewing Deck and Times Travel experience.
      </h2>
      <form>
        <div class="form-row">
          <div class="form-item">
            <FormsInputField
              :value="signupFormData.firstname"
              name="firstname"
              type="text"
              autocomplete="name"
              required
              aria-required="true"
              aria-label="Enter your first name"
              placeholder="First name"
              :error="!validTextInput(signupFormData.firstname) && validationError"
              error-message="Your first name is required"
              @input="(evt) => (signupFormData.firstname = evt)"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-item">
            <FormsInputField
              :value="signupFormData.lastname"
              name="lastname"
              type="text"
              autocomplete="name"
              required
              aria-required="true"
              aria-label="Enter your last name"
              placeholder="Last name"
              :error="!validTextInput(signupFormData.lastname) && validationError"
              error-message="Your last name is required"
              @input="(evt) => (signupFormData.lastname = evt)"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-item">
            <FormsInputField
              :value="signupFormData.email"
              name="email"
              type="email"
              autocomplete="email"
              required
              aria-required="true"
              aria-label="Enter your email"
              placeholder="Email"
              :error="!validEmail(signupFormData.email) && validationError"
              error-message="Email is required"
              @input="(evt) => (signupFormData.email = evt)"
            />
          </div>
        </div>

        <div class="terms">
          By submitting, you grant us permission to email you updates in accordance with our
          <a href="/privacy" target="_blank" aria-label="Read privacy notice">Privacy Notice</a>.
        </div>

        <div class="form-row signup-button">
          <TheButton
            type="submit"
            primary
            class="submit-button"
            :disabled="isSubmitting"
            aria-label="Submit signup"
            @click="onSubmitHandler"
          >
            Sign up
          </TheButton>
        </div>
      </form>
    </div>

    <div v-if="isSubmitting || signupSuccess" class="form-submitting-block">
      <FormsFormSubmitting :success="signupSuccess" :error="!!signupError">
        <template #title>
          <template v-if="!signupError">Your request has been successfully sent! </template>
          <template v-else>There was an error subitting your request </template>
        </template>
        <template #message>
          <template v-if="!signupError">You are now on the waitlist!</template>
          <template v-else>Please try again. </template>
        </template>
      </FormsFormSubmitting>

      <div v-if="signupSuccess" class="back-link">
        <nuxt-link
          :aria-label="!signupError ? 'You have successfully signed up. Close form.' : 'Try again'"
          @click="onFormReset"
        >
          <span v-if="!signupError">Close</span>
          <span v-else>Try again</span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/assets/scss/queries";
@use "@/assets/scss/forms";
.modal-backdrop .the-modal {
  max-width: 620px !important;
}
.signup-form {
  align-items: center;
  display: flex;
  margin: auto;
  max-width: 800px;
  min-height: 575px;
  position: relative;
  width: 800px;

  @include queries.for-size(tablet-portrait-down) {
    padding-top: 1rem;
    width: 100vw;
  }

  @include queries.for-size(phone-only) {
    min-height: 300px;
    padding: 0;
  }

  .back-link {
    color: black;
    cursor: pointer;
    font-size: var(--font-size-p);
    text-align: center;

    a {
      color: black;
    }
  }

  .form-submitting-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.sign-up-component {
  backdrop-filter: blur(1px);
  background: white;
  display: inline-grid;
  grid-gap: 3rem;
  max-width: 620px;
  padding: 3rem;

  @include queries.for-size(phone-only) {
    grid-gap: 1rem;
    padding: 0;
  }

  h2 {
    color: black;
    font-size: 3.6rem;
    font-weight: 800;
    line-height: 1;
    margin: 0;
    padding: 0;

    @include queries.for-size(phone-only) {
      font-size: 3rem;
    }

    span {
      white-space: nowrap;
    }
  }

  h3 {
    color: black;
    font-size: var(--font-size-h3);
    font-weight: 700;
    margin-top: 0;

    @include queries.for-size(phone-only) {
      margin: 0;
    }
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .form-row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      position: relative;
      width: 100%;

      @include queries.for-size(phone-only) {
        flex-direction: column;
      }

      .form-item {
        position: relative;
        width: 100%;
      }

      input {
        border-width: 4px;
      }
    }

    .signup-button {
      .submit-button {
        background-color: #3a1331;
        border-color: #3a1331;
        margin: auto;
        padding-left: 8rem;
        padding-right: 8rem;

        &:hover {
          color: #3a1331;
        }
      }
    }

    .know-more {
      border-width: 0;
      width: 100%;

      legend {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 1rem;

        @include queries.for-size(phone-only) {
          font-size: 2.25rem;
        }
      }
    }

    .terms {
      font-size: 1.1rem;
      padding: 2rem 0;
      text-align: left;
      width: 100%;

      @include queries.for-size(phone-only) {
        font-size: 1.8rem;
        padding: 0;
      }

      a {
        color: black;
      }
    }

    label {
      font-size: 1.6rem;

      @include queries.for-size(phone-only) {
        font-size: 2rem;
      }

      &.terms {
        font-size: 1.3rem;
      }
    }

    .checkboxes {
      display: grid;
      gap: 1rem;
      grid-template-columns: auto auto;
      width: 100%;
    }

    .checkbox {
      align-items: center;
      display: inline-grid;
      gap: 1.5rem;
      grid-template-columns: auto 1fr;

      input[type="checkbox"] {
        accent-color: darkgray;
        border: 3px solid #ddd;
        border-radius: 10px !important;
        height: 30px;
        width: 30px;
      }
    }
  }

  .agree-block {
    color: white;

    p {
      font-size: 1rem;
      text-align: justify;

      @include queries.for-size(phone-only) {
        font-size: 2rem;
      }
    }
  }
}
</style>
